// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PfqhKYWrb_a0YEUZJTgZB{padding:80px 0;width:100%}._2oQAODYGGIys3tr6kNzqQr{width:100%;display:flex;align-items:center;justify-content:space-between}._2p0Z_hVQagM2K7N1XAAyYd{color:var(--blue)}._3q4JdWBsR-9cgQJNFqCrxV{padding-top:5px;display:flex;align-items:center;gap:10px;transition:.2s}._3q4JdWBsR-9cgQJNFqCrxV:hover{opacity:.7;transition:.2s}._3v1Y3GMQBS1lVHdRfB7Cen{color:var(--blue)}@media only screen and (max-width: 1000px){.PfqhKYWrb_a0YEUZJTgZB{padding:40px 0}._2oQAODYGGIys3tr6kNzqQr{flex-wrap:wrap;row-gap:24px}}", "",{"version":3,"sources":["webpack://./src/components/common/LivingRoom/LivingRoom.module.scss"],"names":[],"mappings":"AAAA,uBACE,cAAA,CACA,UAAA,CAGF,yBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,yBACE,iBAAA,CAGF,yBACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CAEA,+BACE,UAAA,CACA,cAAA,CAIJ,yBACE,iBAAA,CAGF,2CACE,uBACE,cAAA,CAGF,yBACE,cAAA,CACA,YAAA,CAAA","sourcesContent":[".wrapper {\n  padding: 80px 0;\n  width: 100%;\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.heading {\n  color: var(--blue);\n}\n\n.links {\n  padding-top: 5px;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  transition: .2s;\n\n  &:hover {\n    opacity: .7;\n    transition: .2s;\n  }\n}\n\n.link {\n  color: var(--blue);\n}\n\n@media only screen and (max-width: 1000px) {\n  .wrapper {\n    padding: 40px 0;\n  }\n\n  .header {\n    flex-wrap: wrap;\n    row-gap: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PfqhKYWrb_a0YEUZJTgZB",
	"header": "_2oQAODYGGIys3tr6kNzqQr",
	"heading": "_2p0Z_hVQagM2K7N1XAAyYd",
	"links": "_3q4JdWBsR-9cgQJNFqCrxV",
	"link": "_3v1Y3GMQBS1lVHdRfB7Cen"
};
export default ___CSS_LOADER_EXPORT___;
