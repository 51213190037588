// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3TMlvlsCIB4zq0NYblb0s_{font-family:\"Commissioner Medium\";font-style:normal;font-weight:500;font-size:30px;line-height:36px}", "",{"version":3,"sources":["webpack://./src/components/kit/H2/H2.module.scss"],"names":[],"mappings":"AAAA,yBACE,iCAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".styled {\n  font-family: 'Commissioner Medium';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 30px;\n  line-height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styled": "_3TMlvlsCIB4zq0NYblb0s_"
};
export default ___CSS_LOADER_EXPORT___;
