import { FC, SVGProps } from 'react';

export const PrefabIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.7254 11.5878L13.9814 7.01294C12.8837 6.88969 11.894 7.67961 11.7707 8.77729L11.072 15H46C48.7614 15 51 17.2386 51 20V46.3813C52.0239 46.4046 52.9143 45.6422 53.0307 44.6048L56.4897 13.7984C56.613 12.7008 55.8231 11.711 54.7254 11.5878ZM51 49.3819C53.5325 49.4068 55.7233 47.511 56.012 44.9396L59.471 14.1332C59.7791 11.389 57.8043 8.91461 55.0601 8.60649L14.3162 4.03167C11.572 3.72355 9.0976 5.69836 8.78948 8.44254L8.0532 15H5C2.23858 15 0 17.2386 0 20V51C0 53.7614 2.23858 56 5 56H46C48.7614 56 51 53.7614 51 51V49.3819ZM5 18H46C47.1046 18 48 18.8954 48 20V33.4085L47.9499 33.4608C47.6577 33.7653 47.2276 34.2026 46.6842 34.7254C45.5951 35.773 44.0618 37.1532 42.2786 38.4928C40.4899 39.8365 38.4866 41.1115 36.4572 41.9769C34.4176 42.8468 32.4579 43.2577 30.7074 43.0143C28.0322 42.6422 26.7502 40.6721 25.0336 38.0281C23.4431 35.5784 21.3877 32.4262 16.9757 32.5002C14.9162 32.5347 12.8409 33.3323 10.9652 34.3791C9.07166 35.4359 7.26244 36.8143 5.70756 38.158C4.69496 39.0331 3.77732 39.9048 3 40.6826V20C3 18.8954 3.89543 18 5 18ZM44.0805 40.8914C45.5973 39.7519 46.9371 38.5914 48 37.6081V51C48 52.1046 47.1046 53 46 53H5C3.89543 53 3 52.1046 3 51V45.0588L3.01219 45.045C3.25279 44.7718 3.60666 44.3783 4.05339 43.9043C4.94862 42.9543 6.208 41.6906 7.66918 40.4278C9.13514 39.1609 10.7738 37.9215 12.4272 36.9987C14.0984 36.0661 15.6688 35.5225 17.026 35.4998C19.6043 35.4566 20.8366 37.0729 22.5173 39.6617C22.5873 39.7695 22.6581 39.8792 22.7299 39.9904L22.7304 39.9911C24.2545 42.3517 26.2364 45.4214 30.2942 45.9857C32.7821 46.3317 35.3165 45.7249 37.6341 44.7365C39.9619 43.7437 42.1788 42.32 44.0805 40.8914ZM34 28.5C34 30.433 32.433 32 30.5 32C28.567 32 27 30.433 27 28.5C27 26.567 28.567 25 30.5 25C32.433 25 34 26.567 34 28.5ZM37 28.5C37 32.0898 34.0899 35 30.5 35C26.9101 35 24 32.0898 24 28.5C24 24.9101 26.9101 22 30.5 22C34.0899 22 37 24.9101 37 28.5Z"
        fill="#B6B9CE"
      />
    </svg>
  );
};
