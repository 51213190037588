// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3zaPZFDyXU870kTQ_A9Rl8{font-family:\"Commissioner Regular\";font-style:normal;font-weight:400;font-size:14px;line-height:17px}._1gM6UU_EJTxa7VLRN-d6HC{font-weight:500;line-height:22px;letter-spacing:.02em}", "",{"version":3,"sources":["webpack://./src/components/kit/H4/H4.module.scss"],"names":[],"mappings":"AAAA,yBACE,kCAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGF,yBACE,eAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":[".styled {\n  font-family: 'Commissioner Regular';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n}\n\n.medium {\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: 0.02em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styled": "_3zaPZFDyXU870kTQ_A9Rl8",
	"medium": "_1gM6UU_EJTxa7VLRN-d6HC"
};
export default ___CSS_LOADER_EXPORT___;
