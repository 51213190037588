import { FC, SVGProps } from 'react';

export const ChevronRightIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="4"
      height="7"
      viewBox="0 0 4 7"
      fill="#8C91B5"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.259405 1.07409C0.0804152 0.8951 0.0804152 0.6049 0.259405 0.425909C0.438396 0.246919 0.728596 0.246919 0.907587 0.425909L0.259405 1.07409ZM3.3335 3.5L3.65759 3.17591L3.98168 3.5L3.65759 3.82409L3.3335 3.5ZM0.907587 6.57409C0.728596 6.75308 0.438396 6.75308 0.259405 6.57409C0.0804152 6.3951 0.0804152 6.1049 0.259405 5.92591L0.907587 6.57409ZM0.907587 0.425909L3.65759 3.17591L3.00941 3.82409L0.259405 1.07409L0.907587 0.425909ZM3.65759 3.82409L0.907587 6.57409L0.259405 5.92591L3.00941 3.17591L3.65759 3.82409Z"
        fill="fill"
      />
    </svg>
  );
};

export const ChevronLeftIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="#8C91B5"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.70711 12.2929C9.09763 12.6834 9.09763 13.3166 8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071L8.70711 12.2929ZM2 7L1.29289 7.70711L0.585786 7L1.29289 6.29289L2 7ZM7.29289 0.292893C7.68342 -0.0976315 8.31658 -0.0976315 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L7.29289 0.292893ZM7.29289 13.7071L1.29289 7.70711L2.70711 6.29289L8.70711 12.2929L7.29289 13.7071ZM1.29289 6.29289L7.29289 0.292893L8.70711 1.70711L2.70711 7.70711L1.29289 6.29289Z"
        fill="fill"
      />
    </svg>
  );
};

export const ChevronVerticalIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.2929 0.292893C12.6834 -0.097631 13.3166 -0.097631 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L12.2929 0.292893ZM7 7L7.70711 7.70711L7 8.41421L6.29289 7.70711L7 7ZM0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.097631 1.31658 -0.097631 1.70711 0.292893L0.292893 1.70711ZM13.7071 1.70711L7.70711 7.70711L6.29289 6.29289L12.2929 0.292893L13.7071 1.70711ZM6.29289 7.70711L0.292893 1.70711L1.70711 0.292893L7.70711 6.29289L6.29289 7.70711Z"
        fill="#8C91B5"
      />
    </svg>
  );
};
