// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1IrmqREpIgQ2vy_z0q0z8k{width:max-content}._1jE4LWSYc4isc3qs3PYUCK{text-transform:uppercase;color:#b6b9ce;margin-bottom:12px}", "",{"version":3,"sources":["webpack://./src/components/kit/Tag/Tag.module.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CAGF,yBACE,wBAAA,CACA,aAAA,CACA,kBAAA","sourcesContent":[".wrapper {\n  width: max-content;\n}\n\n.tag {\n  text-transform: uppercase;\n  color: #B6B9CE;\n  margin-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_1IrmqREpIgQ2vy_z0q0z8k",
	"tag": "_1jE4LWSYc4isc3qs3PYUCK"
};
export default ___CSS_LOADER_EXPORT___;
