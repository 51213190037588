// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._230Btl6qlQ2uKsBob55CEq{display:flex;align-items:center;gap:10px}@media only screen and (max-width: 700px){._230Btl6qlQ2uKsBob55CEq{width:320px;justify-content:space-between}._15olXZpoOfoHvG5CWb3_TM{width:205px}._3EMxgv67tkN7Z5DiSQMP{width:105px}}", "",{"version":3,"sources":["webpack://./src/components/common/Logos/Logos.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,0CACE,yBACE,WAAA,CACA,6BAAA,CAGF,yBACE,WAAA,CAGF,uBACE,WAAA,CAAA","sourcesContent":[".images {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n@media only screen and (max-width: 700px) {\n  .images {\n    width: 320px;\n    justify-content: space-between;\n  }\n  \n  .comitet {\n    width: 205px;\n  }\n\n  .gimc {\n    width: 105px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"images": "_230Btl6qlQ2uKsBob55CEq",
	"comitet": "_15olXZpoOfoHvG5CWb3_TM",
	"gimc": "_3EMxgv67tkN7Z5DiSQMP"
};
export default ___CSS_LOADER_EXPORT___;
