// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3O01r-OcDPFzN_cCZHe11{width:100%;height:100vh;display:flex;align-items:center;justify-content:center}.WgEauKCHOFJYHuYVV3egp *{margin:auto;text-align:center}._2ZzdklKP9YSx_8JQHj3tNV{margin-bottom:40px}._15WYdkXGjmz2oP6feZAWBU{width:max-content}", "",{"version":3,"sources":["webpack://./src/components/common/NotFound/NotFound.module.scss"],"names":[],"mappings":"AAAA,wBACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAIA,yBACE,WAAA,CACA,iBAAA,CAIJ,yBACE,kBAAA,CAGF,yBACE,iBAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.inner {\n  & * {\n    margin: auto;\n    text-align: center;\n  }\n}\n\n.spacer {\n  margin-bottom: 40px;\n}\n\n.resizer {\n  width: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_3O01r-OcDPFzN_cCZHe11",
	"inner": "WgEauKCHOFJYHuYVV3egp",
	"spacer": "_2ZzdklKP9YSx_8JQHj3tNV",
	"resizer": "_15WYdkXGjmz2oP6feZAWBU"
};
export default ___CSS_LOADER_EXPORT___;
