import { FC, SVGProps } from 'react';

export const SocialTgIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.92 5.09205L4.7471 10.7942C3.77986 11.1995 3.78545 11.7624 4.56964 12.0135L8.2084 13.1978L16.6274 7.65579C17.0255 7.40309 17.3892 7.53903 17.0903 7.81592L10.2692 14.2386H10.2676L10.2692 14.2394L10.0182 18.1526C10.3859 18.1526 10.5482 17.9766 10.7544 17.769L12.5218 15.9758L16.1982 18.809C16.876 19.1984 17.3628 18.9983 17.5315 18.1543L19.9448 6.28802C20.1918 5.25468 19.5667 4.78681 18.92 5.09205Z"
        fill="#414FEB"
      />
    </svg>
  );
};
