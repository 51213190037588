// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2qQ_XHm4ojVfdsvqRjSJ0w{width:100%}._1_f_Ae2OKI_F2DJV7ZVTO4{padding-top:40px}.Fbg8YVKMYcMhQ9LAGkJ1t{margin-top:60px;max-width:800px}._1iDkvYt9zMDgCmByiz5RkZ{margin-top:70px;margin-bottom:80px}._2VwydXFfmiTEQZWQKoZU-z{margin-bottom:30px}._9lmbv2CHY6YJ-X5yr1bNp{margin-bottom:60px}._202RMW2Tc-lImIp1kCjygo{margin-bottom:80px}@media only screen and (max-width: 1000px){._1_f_Ae2OKI_F2DJV7ZVTO4{padding-top:24px}._9lmbv2CHY6YJ-X5yr1bNp{margin-bottom:30px}.Fbg8YVKMYcMhQ9LAGkJ1t{margin-top:30px;max-width:800px}._1iDkvYt9zMDgCmByiz5RkZ{margin-top:35px;margin-bottom:40px}._202RMW2Tc-lImIp1kCjygo{margin-bottom:40px}}", "",{"version":3,"sources":["webpack://./src/pages/entities/social/SociotekaSocial/SociotekaSocial.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CAGF,yBACE,gBAAA,CAGF,uBACE,eAAA,CACA,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CAGF,yBACE,kBAAA,CAGF,wBACE,kBAAA,CAGF,yBACE,kBAAA,CAGF,2CACE,yBACE,gBAAA,CAGF,wBACE,kBAAA,CAGF,uBACE,eAAA,CACA,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CAGF,yBACE,kBAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.content {\n  padding-top: 40px;\n}\n\n.heading {\n  margin-top: 60px;\n  max-width: 800px;\n}\n\n.navigation {\n  margin-top: 70px;\n  margin-bottom: 80px;\n}\n\n.search {\n  margin-bottom: 30px;\n}\n\n.filtration {\n  margin-bottom: 60px;\n}\n\n.table {\n  margin-bottom: 80px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .content {\n    padding-top: 24px;\n  }\n\n  .filtration {\n    margin-bottom: 30px;\n  }\n  \n  .heading {\n    margin-top: 30px;\n    max-width: 800px;\n  }\n\n  .navigation {\n    margin-top: 35px;\n    margin-bottom: 40px;\n  }\n  \n  .table {\n    margin-bottom: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_2qQ_XHm4ojVfdsvqRjSJ0w",
	"content": "_1_f_Ae2OKI_F2DJV7ZVTO4",
	"heading": "Fbg8YVKMYcMhQ9LAGkJ1t",
	"navigation": "_1iDkvYt9zMDgCmByiz5RkZ",
	"search": "_2VwydXFfmiTEQZWQKoZU-z",
	"filtration": "_9lmbv2CHY6YJ-X5yr1bNp",
	"table": "_202RMW2Tc-lImIp1kCjygo"
};
export default ___CSS_LOADER_EXPORT___;
