// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2K47ctXRKFG9INKgqKHKLW{font-family:\"Commissioner Medium\";font-style:normal;font-weight:500;font-size:40px;line-height:50px;color:#130914}@media only screen and (max-width: 700px){._2K47ctXRKFG9INKgqKHKLW{font-size:30px;line-height:40px}}", "",{"version":3,"sources":["webpack://./src/components/kit/LandingHeading/LandingHeading.module.scss"],"names":[],"mappings":"AAAA,yBACE,iCAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aAAA,CAGF,0CACE,yBACE,cAAA,CACA,gBAAA,CAAA","sourcesContent":[".styled {\n  font-family: 'Commissioner Medium';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 40px;\n  line-height: 50px;\n\n  color: #130914;\n}\n\n@media only screen and (max-width: 700px) {\n  .styled {\n    font-size: 30px;\n    line-height: 40px;\n  \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styled": "_2K47ctXRKFG9INKgqKHKLW"
};
export default ___CSS_LOADER_EXPORT___;
