// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3iIKn8fZIcQ6qLrxXEcM5-{width:max-content;display:flex;align-items:center;justify-content:center;gap:20px;cursor:pointer;transition:.2s}._3iIKn8fZIcQ6qLrxXEcM5-:hover{opacity:.7;transition:.2s}._3iIKn8fZIcQ6qLrxXEcM5- *{color:var(--blue)}", "",{"version":3,"sources":["webpack://./src/components/kit/BackButton/BackButton.module.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,cAAA,CACA,cAAA,CAEA,+BACE,UAAA,CACA,cAAA,CAGF,2BACE,iBAAA","sourcesContent":[".wrapper {\n  width: max-content;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n  cursor: pointer;\n  transition: .2s;\n\n  &:hover {\n    opacity: .7;\n    transition: .2s;\n  }\n\n  & * {\n    color: var(--blue);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_3iIKn8fZIcQ6qLrxXEcM5-"
};
export default ___CSS_LOADER_EXPORT___;
