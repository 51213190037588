// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7-LCQeoub5pyo6AXBlyr{position:relative;display:flex;align-items:center;justify-content:center;width:100%;height:100vh}", "",{"version":3,"sources":["webpack://./src/components/common/PageLoader/PageLoader.module.scss"],"names":[],"mappings":"AAAA,uBACC,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,YAAA","sourcesContent":[".wrapper {\n\tposition: relative;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 100%;\n\theight: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_7-LCQeoub5pyo6AXBlyr"
};
export default ___CSS_LOADER_EXPORT___;
