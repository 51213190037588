// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._10UxCRxHklL0xikOgoRjeh{display:flex;align-items:center;gap:12px;padding:4px 0}._10UxCRxHklL0xikOgoRjeh *{color:var(--gray)}", "",{"version":3,"sources":["webpack://./src/components/common/LibraryLoadingIndicator/LibraryLoadingIndicator.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,aAAA,CAEA,2BACE,iBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 4px 0;\n  \n  & * {\n    color: var(--gray)\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_10UxCRxHklL0xikOgoRjeh"
};
export default ___CSS_LOADER_EXPORT___;
