// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1JKwugGu3RO54abR-SQ8j{width:100%}._2O0bjIf8mREmvH6rnuPrVA{padding-top:40px}._3zR86lhTkKKBQOQ3iGHydO{margin-top:60px;max-width:800px}._2tuyyJFShQZP2TCxgYCIb7{margin-top:70px;margin-bottom:80px}._2SEHg9Bes2sb4lzaClDJiN{margin-bottom:30px}._2QMAGDxR_8Po6MYBVziz3Z{margin-bottom:60px}._2xabTiUtecyHBJNSxzxWNc{margin-bottom:80px}@media only screen and (max-width: 1000px){._2O0bjIf8mREmvH6rnuPrVA{padding-top:24px}._2QMAGDxR_8Po6MYBVziz3Z{margin-bottom:30px}._3zR86lhTkKKBQOQ3iGHydO{margin-top:30px;max-width:800px}._2tuyyJFShQZP2TCxgYCIb7{margin-top:35px;margin-bottom:40px}._2xabTiUtecyHBJNSxzxWNc{margin-bottom:40px}}", "",{"version":3,"sources":["webpack://./src/pages/entities/club/SociotekaClubs/SociotekaClubs.module.scss"],"names":[],"mappings":"AAAA,wBACE,UAAA,CAGF,yBACE,gBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CAGF,yBACE,kBAAA,CAGF,yBACE,kBAAA,CAGF,yBACE,kBAAA,CAGF,2CACE,yBACE,gBAAA,CAGF,yBACE,kBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CAGF,yBACE,kBAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.content {\n  padding-top: 40px;\n}\n\n.heading {\n  margin-top: 60px;\n  max-width: 800px;\n}\n\n.navigation {\n  margin-top: 70px;\n  margin-bottom: 80px;\n}\n\n.search {\n  margin-bottom: 30px;\n}\n\n.filtration {\n  margin-bottom: 60px;\n}\n\n.table {\n  margin-bottom: 80px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .content {\n    padding-top: 24px;\n  }\n\n  .filtration {\n    margin-bottom: 30px;\n  }\n  \n  .heading {\n    margin-top: 30px;\n    max-width: 800px;\n  }\n\n  .navigation {\n    margin-top: 35px;\n    margin-bottom: 40px;\n  }\n  \n  .table {\n    margin-bottom: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_1JKwugGu3RO54abR-SQ8j",
	"content": "_2O0bjIf8mREmvH6rnuPrVA",
	"heading": "_3zR86lhTkKKBQOQ3iGHydO",
	"navigation": "_2tuyyJFShQZP2TCxgYCIb7",
	"search": "_2SEHg9Bes2sb4lzaClDJiN",
	"filtration": "_2QMAGDxR_8Po6MYBVziz3Z",
	"table": "_2xabTiUtecyHBJNSxzxWNc"
};
export default ___CSS_LOADER_EXPORT___;
