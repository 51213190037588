// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2ZYPGCojJqrSHISB4dHzQa{width:100%}._1SMh8lVtDZphMHArlMEt8L{padding-top:40px}._2x_nkl43HZ5AsFJKC2Gkru{margin-top:60px;max-width:800px}._1TK5rRpj81zC1KdG-iVN7A{margin-top:70px;margin-bottom:80px}.TFvgPIfSBMHnJQAkmVjkZ{margin-bottom:30px}._2PLykOBSnJwYjh614wCewL{margin-bottom:60px}.HztYGgAs64HC5oAFrISP4{margin-bottom:80px}@media only screen and (max-width: 1000px){._1SMh8lVtDZphMHArlMEt8L{padding-top:24px}._2PLykOBSnJwYjh614wCewL{margin-bottom:30px}._2x_nkl43HZ5AsFJKC2Gkru{margin-top:30px;max-width:800px}._1TK5rRpj81zC1KdG-iVN7A{margin-top:35px;margin-bottom:40px}.HztYGgAs64HC5oAFrISP4{margin-bottom:40px}}", "",{"version":3,"sources":["webpack://./src/pages/entities/methodology/SociotekaMethodologies/SociotekaMethodologies.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CAGF,yBACE,gBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CAGF,uBACE,kBAAA,CAGF,yBACE,kBAAA,CAGF,uBACE,kBAAA,CAGF,2CACE,yBACE,gBAAA,CAGF,yBACE,kBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CAGF,uBACE,kBAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.content {\n  padding-top: 40px;\n}\n\n.heading {\n  margin-top: 60px;\n  max-width: 800px;\n}\n\n.navigation {\n  margin-top: 70px;\n  margin-bottom: 80px;\n}\n\n.search {\n  margin-bottom: 30px;\n}\n\n.filtration {\n  margin-bottom: 60px;\n}\n\n.table {\n  margin-bottom: 80px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .content {\n    padding-top: 24px;\n  }\n\n  .filtration {\n    margin-bottom: 30px;\n  }\n  \n  .heading {\n    margin-top: 30px;\n    max-width: 800px;\n  }\n\n  .navigation {\n    margin-top: 35px;\n    margin-bottom: 40px;\n  }\n  \n  .table {\n    margin-bottom: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_2ZYPGCojJqrSHISB4dHzQa",
	"content": "_1SMh8lVtDZphMHArlMEt8L",
	"heading": "_2x_nkl43HZ5AsFJKC2Gkru",
	"navigation": "_1TK5rRpj81zC1KdG-iVN7A",
	"search": "TFvgPIfSBMHnJQAkmVjkZ",
	"filtration": "_2PLykOBSnJwYjh614wCewL",
	"table": "HztYGgAs64HC5oAFrISP4"
};
export default ___CSS_LOADER_EXPORT___;
