// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1TNLvGijQLCdylXFJEWJ-p{width:100%;background:var(--gray);border-radius:8px;display:flex;align-items:center;justify-content:center}._1BtQRiIgLlqVNzXxSVfHXt{min-height:60px}.S3QjbB0SRB1r3ff4Hclf1{width:100%;height:max-content}._1HDc5TSCFCRHJE6fQLughe{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/components/kit/Skeleton/Skeleton.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,sBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,yBACE,eAAA,CAIJ,uBACE,UAAA,CACA,kBAAA,CAGF,yBACE,iBAAA","sourcesContent":[".styled {\n  width: 100%;\n  background: var(--gray);\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &_input {\n    min-height: 60px;\n  }\n}\n\n.wrapper {\n  width: 100%;\n  height: max-content;\n}\n\n.heading {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styled": "_1TNLvGijQLCdylXFJEWJ-p",
	"styled_input": "_1BtQRiIgLlqVNzXxSVfHXt",
	"wrapper": "S3QjbB0SRB1r3ff4Hclf1",
	"heading": "_1HDc5TSCFCRHJE6fQLughe"
};
export default ___CSS_LOADER_EXPORT___;
