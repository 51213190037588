// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2-zp5pIfpMqfcBqAfrNoXI{width:100%;padding-bottom:60px}.t4VnlyzLOcfrr1zMA9nu{width:100%;display:flex;justify-content:space-between;gap:40px}.jAGEU4Xbt5LbStqTVe13_{flex-shrink:0;width:200px}._2NxQocFjBEtkcIpokAvMgX{margin-top:40px}.kjEh-RYYF-ZtQM0U5qZ_a{margin-top:60px;max-width:568px}@media only screen and (max-width: 1000px){.t4VnlyzLOcfrr1zMA9nu{flex-wrap:wrap;gap:24px}.jAGEU4Xbt5LbStqTVe13_{flex-shrink:0;width:100%}}@media only screen and (max-width: 700px){._2-zp5pIfpMqfcBqAfrNoXI{padding-bottom:24px}.kjEh-RYYF-ZtQM0U5qZ_a{margin-top:24px;max-width:320px}}", "",{"version":3,"sources":["webpack://./src/components/common/CommonEntityPreview/CommonEntityPreview.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,mBAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,QAAA,CAEA,uBACE,aAAA,CACA,WAAA,CAIJ,yBACE,eAAA,CAGF,uBACE,eAAA,CACA,eAAA,CAGF,2CACE,sBACE,cAAA,CACA,QAAA,CAEA,uBACE,aAAA,CACA,UAAA,CAAA,CAKN,0CACE,yBACE,mBAAA,CAGF,uBACE,eAAA,CACA,eAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  padding-bottom: 60px;\n}\n\n.meta {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  gap: 40px;\n\n  &__tags {\n    flex-shrink: 0;\n    width: 200px;\n  }\n}\n\n.nextTag {\n  margin-top: 40px;\n}\n\n.gallery {\n  margin-top: 60px;\n  max-width: 568px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .meta {\n    flex-wrap: wrap;\n    gap: 24px;\n  \n    &__tags {\n      flex-shrink: 0;\n      width: 100%;\n    }\n  }\n}\n\n@media only screen and (max-width: 700px) {\n  .wrapper {\n    padding-bottom: 24px;\n  }\n\n  .gallery {\n    margin-top: 24px;\n    max-width: 320px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_2-zp5pIfpMqfcBqAfrNoXI",
	"meta": "t4VnlyzLOcfrr1zMA9nu",
	"meta__tags": "jAGEU4Xbt5LbStqTVe13_",
	"nextTag": "_2NxQocFjBEtkcIpokAvMgX",
	"gallery": "kjEh-RYYF-ZtQM0U5qZ_a"
};
export default ___CSS_LOADER_EXPORT___;
