// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2hiEM3ZeWvnZKFmqzBnskt{position:relative}._1lzwVziHcJOwphSrJZlZIx{position:absolute;top:20px;left:20px;max-width:230px;width:max-content;padding:10px 15px;border-radius:8px;background-color:var(--hint-gray);z-index:10}._1dRF1jBNS9MZhEQT5O5AmL{left:unset;right:20px}._3k5GYda43DMHSziNzux9UN{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/kit/Hint/Hint.module.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CAGF,yBACE,iBAAA,CACA,QAAA,CACA,SAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,iCAAA,CACA,UAAA,CAEA,yBACE,UAAA,CACA,UAAA,CAIJ,yBACE,cAAA","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.hint {\n  position: absolute;\n  top: 20px;\n  left: 20px;\n  max-width: 230px;\n  width: max-content;\n  padding: 10px 15px;\n  border-radius: 8px;\n  background-color: var(--hint-gray);\n  z-index: 10;\n\n  &_left {\n    left: unset;\n    right: 20px;\n  }\n}\n\n.cursor {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_2hiEM3ZeWvnZKFmqzBnskt",
	"hint": "_1lzwVziHcJOwphSrJZlZIx",
	"hint_left": "_1dRF1jBNS9MZhEQT5O5AmL",
	"cursor": "_3k5GYda43DMHSziNzux9UN"
};
export default ___CSS_LOADER_EXPORT___;
