// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2I78jwtLvaLVdJkfjMxwlB{width:20px;height:20px;animation:_1N4cUs_bRRqw6ZcgMsj-wH 2s linear infinite}.MDQ5q3_1RyAuR0EeMrGVx{width:15px;height:15px;animation:_1N4cUs_bRRqw6ZcgMsj-wH 1.5s linear infinite}.MDQ5q3_1RyAuR0EeMrGVx svg{transform:translateY(-2px)}@keyframes _1N4cUs_bRRqw6ZcgMsj-wH{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/components/kit/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,WAAA,CACA,oDAAA,CAEA,uBACE,UAAA,CACA,WAAA,CACA,sDAAA,CAEA,2BACE,0BAAA,CAKN,mCACE,KACE,sBAAA,CAEF,GACE,wBAAA,CAAA","sourcesContent":[".styled {\n  width: 20px;\n  height: 20px;\n  animation: spin 2s linear infinite;\n\n  &_small {\n    width: 15px;\n    height: 15px;\n    animation: spin 1.5s linear infinite;\n\n    & svg {\n      transform: translateY(-2px);\n    }\n  }\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styled": "_2I78jwtLvaLVdJkfjMxwlB",
	"spin": "_1N4cUs_bRRqw6ZcgMsj-wH",
	"styled_small": "MDQ5q3_1RyAuR0EeMrGVx"
};
export default ___CSS_LOADER_EXPORT___;
