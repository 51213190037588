// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2-3PMNTZOf5tl1P32mTjlO{width:100%}.xSNvdhgFv0yZqirtiPNNX{margin-top:40px}._3p9fT4MJR0-_ck03NfUSeH{margin-top:60px;margin-bottom:35px;text-transform:uppercase;color:#8c91b5}.HYQzdBGJ_p4JkBh0S4o6z{padding:60px 0;background:var(--white)}._3ku1t6XaF7Ffn1XaXoYCBb{padding:60px 0}@media only screen and (max-width: 1000px){._3p9fT4MJR0-_ck03NfUSeH{margin-top:40px;margin-bottom:20px}.HYQzdBGJ_p4JkBh0S4o6z{padding:40px 0}._3ku1t6XaF7Ffn1XaXoYCBb{padding:40px 0}}", "",{"version":3,"sources":["webpack://./src/pages/entities/education/EducationPreview/EducationPreview.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CAGF,uBACE,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CACA,wBAAA,CACA,aAAA,CAGF,uBACE,cAAA,CACA,uBAAA,CAGF,yBACE,cAAA,CAGF,2CACE,yBACE,eAAA,CACA,kBAAA,CAGF,uBACE,cAAA,CAGF,yBACE,cAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.header {\n  margin-top: 40px;\n}\n\n.tag {\n  margin-top: 60px;\n  margin-bottom: 35px;\n  text-transform: uppercase;\n  color: rgba(140, 145, 181, 1);\n}\n\n.content {\n  padding: 60px 0;\n  background: var(--white);\n}\n\n.footer {\n  padding: 60px 0;\n}\n\n@media only screen and (max-width: 1000px) {\n  .tag {\n    margin-top: 40px;\n    margin-bottom: 20px;\n  }\n  \n  .content {\n    padding: 40px 0;\n  }\n\n  .footer {\n    padding: 40px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_2-3PMNTZOf5tl1P32mTjlO",
	"header": "xSNvdhgFv0yZqirtiPNNX",
	"tag": "_3p9fT4MJR0-_ck03NfUSeH",
	"content": "HYQzdBGJ_p4JkBh0S4o6z",
	"footer": "_3ku1t6XaF7Ffn1XaXoYCBb"
};
export default ___CSS_LOADER_EXPORT___;
