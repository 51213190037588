// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._17HPrkoDoErQbTdCdl840Y{font-style:normal;font-weight:400;font-size:16px;line-height:22px}.hKYC1_EN6a2dAYFCku_zG{font-family:\"Commissioner Medium\"}._3hMGsn3Q9FD9udAOi3EUR5{font-family:\"Commissioner Regular\"}", "",{"version":3,"sources":["webpack://./src/components/kit/H3/H3.module.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,uBACE,iCAAA,CAGF,yBACE,kCAAA","sourcesContent":[".styled {\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 22px;\n\n  &_medium {\n    font-family: 'Commissioner Medium';\n  }\n\n  &_regular {\n    font-family: 'Commissioner Regular';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styled": "_17HPrkoDoErQbTdCdl840Y",
	"styled_medium": "hKYC1_EN6a2dAYFCku_zG",
	"styled_regular": "_3hMGsn3Q9FD9udAOi3EUR5"
};
export default ___CSS_LOADER_EXPORT___;
