// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3JTFL9ynuDPFeIZLy_sbJ6{width:1280px;margin:auto}@media only screen and (max-width: 1300px){._3JTFL9ynuDPFeIZLy_sbJ6{width:980px}}@media only screen and (max-width: 1000px){._3JTFL9ynuDPFeIZLy_sbJ6{width:680px}}@media only screen and (max-width: 700px){._3JTFL9ynuDPFeIZLy_sbJ6{width:320px}}", "",{"version":3,"sources":["webpack://./src/components/common/Wrapper/Wrapper.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,WAAA,CAGF,2CACE,yBACE,WAAA,CAAA,CAIJ,2CACE,yBACE,WAAA,CAAA,CAIJ,0CACE,yBACE,WAAA,CAAA","sourcesContent":[".container {\n  width: 1280px;\n  margin: auto;\n}\n\n@media only screen and (max-width: 1300px) {\n  .container {\n    width: 980px;\n  }\n}\n\n@media only screen and (max-width: 1000px) {\n  .container {\n    width: 680px;\n  }\n}\n\n@media only screen and (max-width: 700px) {\n  .container {\n    width: 320px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3JTFL9ynuDPFeIZLy_sbJ6"
};
export default ___CSS_LOADER_EXPORT___;
