// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ySw6jfaYDIpVeEiCGBWp1{width:max-content;display:flex;align-items:center;gap:4px}._1ySw6jfaYDIpVeEiCGBWp1 *{color:var(--blue)}._1ySw6jfaYDIpVeEiCGBWp1 svg{flex-shrink:0}", "",{"version":3,"sources":["webpack://./src/components/kit/Link/Link.module.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,2BACE,iBAAA,CAGF,6BACE,aAAA","sourcesContent":[".wrapper {\n  width: max-content;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n\n  & * {\n    color: var(--blue);\n  }\n\n  & svg {\n    flex-shrink: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_1ySw6jfaYDIpVeEiCGBWp1"
};
export default ___CSS_LOADER_EXPORT___;
