// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._29N9bCuCQM53CidAf-CkSe{width:100%}._3uS0Nc7lQ88a0DnTVoCkcV{margin-top:40px}._1_kjUoyFBp9_eOveCIuHKj{margin-top:60px;margin-bottom:35px;text-transform:uppercase;color:#8c91b5}._36Y-VGBBUlHiwM0M52MtIp{padding:60px 0;background:var(--white)}._16WffouDaw8CRoL4RfWb3J{padding:60px 0}@media only screen and (max-width: 1000px){._1_kjUoyFBp9_eOveCIuHKj{margin-top:40px;margin-bottom:20px}._36Y-VGBBUlHiwM0M52MtIp{padding:40px 0}._16WffouDaw8CRoL4RfWb3J{padding:40px 0}}", "",{"version":3,"sources":["webpack://./src/pages/entities/club/ClubPreview/ClubPreview.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CAGF,yBACE,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CACA,wBAAA,CACA,aAAA,CAGF,yBACE,cAAA,CACA,uBAAA,CAGF,yBACE,cAAA,CAGF,2CACE,yBACE,eAAA,CACA,kBAAA,CAGF,yBACE,cAAA,CAGF,yBACE,cAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.header {\n  margin-top: 40px;\n}\n\n.tag {\n  margin-top: 60px;\n  margin-bottom: 35px;\n  text-transform: uppercase;\n  color: rgba(140, 145, 181, 1);\n}\n\n.content {\n  padding: 60px 0;\n  background: var(--white);\n}\n\n.footer {\n  padding: 60px 0;\n}\n\n@media only screen and (max-width: 1000px) {\n  .tag {\n    margin-top: 40px;\n    margin-bottom: 20px;\n  }\n  \n  .content {\n    padding: 40px 0;\n  }\n\n  .footer {\n    padding: 40px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_29N9bCuCQM53CidAf-CkSe",
	"header": "_3uS0Nc7lQ88a0DnTVoCkcV",
	"tag": "_1_kjUoyFBp9_eOveCIuHKj",
	"content": "_36Y-VGBBUlHiwM0M52MtIp",
	"footer": "_16WffouDaw8CRoL4RfWb3J"
};
export default ___CSS_LOADER_EXPORT___;
