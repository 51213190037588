// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Ut4Jaa_Ml6ULqU6Bo6bfd{width:100%}._3oO735iOEr0APU7_7Qn0is{margin-top:40px}._2QNat-4rJOOgfb3C9XSh25{margin-top:60px;margin-bottom:35px;text-transform:uppercase;color:#8c91b5}.QvBwbkA3ZFYjq_yuv-lCE{padding:60px 0;background:var(--white)}.bJN74mTYc_bJr3qv14t3g{padding:60px 0}@media only screen and (max-width: 1000px){._2QNat-4rJOOgfb3C9XSh25{margin-top:40px;margin-bottom:20px}.QvBwbkA3ZFYjq_yuv-lCE{padding:40px 0}.bJN74mTYc_bJr3qv14t3g{padding:40px 0}}", "",{"version":3,"sources":["webpack://./src/pages/entities/project/ProjectPreview/ProjectPreview.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CAGF,yBACE,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CACA,wBAAA,CACA,aAAA,CAGF,uBACE,cAAA,CACA,uBAAA,CAGF,uBACE,cAAA,CAGF,2CACE,yBACE,eAAA,CACA,kBAAA,CAGF,uBACE,cAAA,CAGF,uBACE,cAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.header {\n  margin-top: 40px;\n}\n\n.tag {\n  margin-top: 60px;\n  margin-bottom: 35px;\n  text-transform: uppercase;\n  color: rgba(140, 145, 181, 1);\n}\n\n.content {\n  padding: 60px 0;\n  background: var(--white);\n}\n\n.footer {\n  padding: 60px 0;\n}\n\n@media only screen and (max-width: 1000px) {\n  .tag {\n    margin-top: 40px;\n    margin-bottom: 20px;\n  }\n  \n  .content {\n    padding: 40px 0;\n  }\n\n  .footer {\n    padding: 40px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_1Ut4Jaa_Ml6ULqU6Bo6bfd",
	"header": "_3oO735iOEr0APU7_7Qn0is",
	"tag": "_2QNat-4rJOOgfb3C9XSh25",
	"content": "QvBwbkA3ZFYjq_yuv-lCE",
	"footer": "bJN74mTYc_bJr3qv14t3g"
};
export default ___CSS_LOADER_EXPORT___;
