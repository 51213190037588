import { FC, SVGProps } from 'react';

export const LockHiddenIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4 13C4 11.1144 4 10.1716 4.58579 9.58579C5.17157 9 6.11438 9 8 9H16C17.8856 9 18.8284 9 19.4142 9.58579C20 10.1716 20 11.1144 20 13V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V13Z"
        stroke="#8C91B5"
        strokeWidth="2"
      />
      <path
        d="M16 8V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7V8"
        stroke="#8C91B5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="12" cy="15" r="2" fill="#8C91B5" />
    </svg>
  );
};

export const LockShownIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.87868 20.1216L4.17157 20.8287L4.87868 20.1216ZM19.1213 20.1216L18.4142 19.4145L19.1213 20.1216ZM19.4142 9.58603L20.1213 8.87892L19.4142 9.58603ZM8 10.0002H16V8.00024H8V10.0002ZM19 13.0002V15.0002H21V13.0002H19ZM14 20.0002H10V22.0002H14V20.0002ZM5 15.0002V13.0002H3V15.0002H5ZM10 20.0002C8.55752 20.0002 7.57625 19.9981 6.84143 19.8993C6.13538 19.8044 5.80836 19.637 5.58579 19.4145L4.17157 20.8287C4.82768 21.4848 5.64711 21.7567 6.57494 21.8815C7.474 22.0024 8.61406 22.0002 10 22.0002V20.0002ZM3 15.0002C3 16.3862 2.99788 17.5262 3.11875 18.4253C3.2435 19.3531 3.51546 20.1726 4.17157 20.8287L5.58579 19.4145C5.36322 19.1919 5.19584 18.8649 5.10092 18.1588C5.00212 17.424 5 16.4427 5 15.0002H3ZM19 15.0002C19 16.4427 18.9979 17.424 18.8991 18.1588C18.8042 18.8649 18.6368 19.1919 18.4142 19.4145L19.8284 20.8287C20.4845 20.1726 20.7565 19.3531 20.8812 18.4253C21.0021 17.5262 21 16.3862 21 15.0002H19ZM14 22.0002C15.3859 22.0002 16.526 22.0024 17.4251 21.8815C18.3529 21.7567 19.1723 21.4848 19.8284 20.8287L18.4142 19.4145C18.1916 19.637 17.8646 19.8044 17.1586 19.8993C16.4237 19.9981 15.4425 20.0002 14 20.0002V22.0002ZM16 10.0002C16.9711 10.0002 17.5988 10.0024 18.0613 10.0646C18.495 10.1229 18.631 10.217 18.7071 10.2931L20.1213 8.87892C19.6117 8.36926 18.9833 8.17052 18.3278 8.08238C17.701 7.99812 16.9145 8.00024 16 8.00024V10.0002ZM21 13.0002C21 12.0857 21.0021 11.2992 20.9179 10.6725C20.8297 10.0169 20.631 9.38859 20.1213 8.87892L18.7071 10.2931C18.7832 10.3693 18.8774 10.5052 18.9357 10.9389C18.9979 11.4015 19 12.0292 19 13.0002H21ZM8 8.00024C7.08546 8.00024 6.29896 7.99812 5.67221 8.08238C5.01669 8.17052 4.38834 8.36926 3.87868 8.87892L5.29289 10.2931C5.36902 10.217 5.50496 10.1229 5.9387 10.0646C6.40121 10.0024 7.02892 10.0002 8 10.0002V8.00024ZM5 13.0002C5 12.0292 5.00212 11.4015 5.06431 10.9389C5.12262 10.5052 5.21677 10.3693 5.29289 10.2931L3.87868 8.87892C3.36902 9.38859 3.17027 10.0169 3.08214 10.6725C2.99788 11.2992 3 12.0857 3 13.0002H5Z"
        fill="#8C91B5"
      />
      <path
        d="M15.5077 8.87603C15.4392 9.42405 15.8279 9.92384 16.3759 9.99234C16.9239 10.0608 17.4237 9.67211 17.4922 9.12408L15.5077 8.87603ZM6.01521 3.47581C5.59772 3.83736 5.55238 4.4689 5.91393 4.88639C6.27549 5.30388 6.90703 5.34922 7.32451 4.98767L6.01521 3.47581ZM17.4922 9.12408L17.5698 8.50349L15.5852 8.25544L15.5077 8.87603L17.4922 9.12408ZM6.83353 2.76712L6.01521 3.47581L7.32451 4.98767L8.14284 4.27898L6.83353 2.76712ZM14.7246 2.2758C12.2837 0.643783 9.05308 0.844936 6.83353 2.76712L8.14284 4.27898C9.68143 2.94652 11.9209 2.80708 13.6129 3.93839L14.7246 2.2758ZM17.5698 8.50349C17.8756 6.05654 16.7746 3.64646 14.7246 2.2758L13.6129 3.93839C15.034 4.88854 15.7972 6.55921 15.5852 8.25544L17.5698 8.50349Z"
        fill="#8C91B5"
      />
      <circle cx="12" cy="15" r="2" fill="#8C91B5" />
    </svg>
  );
};
