// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3SSeb-EIqkRT5osnFEpi_w{font-style:normal;font-weight:400;font-size:18px}._3xCmcVF4ICOFwKjUy_jpMB{font-family:\"Commissioner Medium\";line-height:24px}.kEAR09coeJVvnq1mwIgro{font-family:\"Commissioner Regular\";line-height:22px}", "",{"version":3,"sources":["webpack://./src/components/kit/Text/Text.module.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CACA,eAAA,CACA,cAAA,CAEA,yBACE,iCAAA,CACA,gBAAA,CAGF,uBACE,kCAAA,CACA,gBAAA","sourcesContent":[".styled {\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n\n  &_medium {\n    font-family: 'Commissioner Medium';\n    line-height: 24px;\n  }\n\n  &_regular {\n    font-family: 'Commissioner Regular';\n    line-height: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styled": "_3SSeb-EIqkRT5osnFEpi_w",
	"styled_medium": "_3xCmcVF4ICOFwKjUy_jpMB",
	"styled_regular": "kEAR09coeJVvnq1mwIgro"
};
export default ___CSS_LOADER_EXPORT___;
