import { FC, SVGProps } from 'react';

export const LoaderDarkIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.358 0.0914308C10.9139 0.0311404 10.4606 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 9.52719 19.9672 9.06203 19.9037 8.6067H17.043C17.1317 9.05742 17.1781 9.52329 17.1781 10C17.1781 13.9644 13.9644 17.1781 10 17.1781C6.03563 17.1781 2.82187 13.9644 2.82187 10C2.82187 6.03563 6.03563 2.82187 10 2.82187C10.4643 2.82187 10.9183 2.86595 11.358 2.95015V0.0914308Z"
        fill="#414FEB"
      />
    </svg>
  );
};

export const LoaderGrayIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <mask id="path-1-inside-1_1978_21" fill="white">
        <path d="M15 7.5C15 6.01664 14.5601 4.56659 13.736 3.33322C12.9119 2.09985 11.7406 1.13856 10.3701 0.570903C8.99968 0.00324625 7.49168 -0.145279 6.03682 0.14411C4.58196 0.4335 3.24559 1.14781 2.1967 2.1967C1.14781 3.24559 0.4335 4.58196 0.14411 6.03682C-0.145279 7.49168 0.00324627 8.99968 0.570904 10.3701C1.13856 11.7406 2.09985 12.9119 3.33322 13.736C4.56659 14.5601 6.01664 15 7.5 15V13.0774C6.39689 13.0774 5.31856 12.7503 4.40136 12.1375C3.48415 11.5246 2.76928 10.6535 2.34714 9.63438C1.925 8.61524 1.81455 7.49381 2.02975 6.4119C2.24496 5.32999 2.77616 4.33619 3.55617 3.55617C4.33619 2.77616 5.32999 2.24496 6.4119 2.02975C7.49381 1.81455 8.61524 1.925 9.63438 2.34714C10.6535 2.76928 11.5246 3.48415 12.1375 4.40136C12.7503 5.31856 13.0774 6.39689 13.0774 7.5H15Z" />
      </mask>
      <path
        d="M15 7.5C15 6.01664 14.5601 4.56659 13.736 3.33322C12.9119 2.09985 11.7406 1.13856 10.3701 0.570903C8.99968 0.00324625 7.49168 -0.145279 6.03682 0.14411C4.58196 0.4335 3.24559 1.14781 2.1967 2.1967C1.14781 3.24559 0.4335 4.58196 0.14411 6.03682C-0.145279 7.49168 0.00324627 8.99968 0.570904 10.3701C1.13856 11.7406 2.09985 12.9119 3.33322 13.736C4.56659 14.5601 6.01664 15 7.5 15V13.0774C6.39689 13.0774 5.31856 12.7503 4.40136 12.1375C3.48415 11.5246 2.76928 10.6535 2.34714 9.63438C1.925 8.61524 1.81455 7.49381 2.02975 6.4119C2.24496 5.32999 2.77616 4.33619 3.55617 3.55617C4.33619 2.77616 5.32999 2.24496 6.4119 2.02975C7.49381 1.81455 8.61524 1.925 9.63438 2.34714C10.6535 2.76928 11.5246 3.48415 12.1375 4.40136C12.7503 5.31856 13.0774 6.39689 13.0774 7.5H15Z"
        stroke="#8C91B5"
        stroke-width="2"
        mask="url(#path-1-inside-1_1978_21)"
      />
    </svg>
  );
};

export const LoaderLightIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.358 0.0914308C10.9139 0.0311404 10.4606 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 9.52719 19.9672 9.06203 19.9037 8.6067H17.043C17.1317 9.05742 17.1781 9.52329 17.1781 10C17.1781 13.9644 13.9644 17.1781 10 17.1781C6.03563 17.1781 2.82187 13.9644 2.82187 10C2.82187 6.03563 6.03563 2.82187 10 2.82187C10.4643 2.82187 10.9183 2.86595 11.358 2.95015V0.0914308Z"
        fill="white"
      />
    </svg>
  );
};
