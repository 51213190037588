// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AJkvrv3DUvtl3gJMVEGrR{width:100%}._1HiT08lBcAcpJvzj0lluBG{margin-top:40px}.SGyFGb-0lmEb5Cp4Cb66Q{margin-top:60px;margin-bottom:35px;text-transform:uppercase;color:#8c91b5}._3JQY6RbT-5PAI81kIP6iCb{padding:60px 0;background:var(--white)}._1jmzuQz0kAcK7Bcclo0N0O{padding:60px 0}@media only screen and (max-width: 1000px){.SGyFGb-0lmEb5Cp4Cb66Q{margin-top:40px;margin-bottom:20px}._3JQY6RbT-5PAI81kIP6iCb{padding:40px 0}._1jmzuQz0kAcK7Bcclo0N0O{padding:40px 0}}", "",{"version":3,"sources":["webpack://./src/pages/entities/methodology/MethodologiesPreview/MethodologiesPreview.module.scss"],"names":[],"mappings":"AAAA,uBACE,UAAA,CAGF,yBACE,eAAA,CAGF,uBACE,eAAA,CACA,kBAAA,CACA,wBAAA,CACA,aAAA,CAGF,yBACE,cAAA,CACA,uBAAA,CAGF,yBACE,cAAA,CAGF,2CACE,uBACE,eAAA,CACA,kBAAA,CAGF,yBACE,cAAA,CAGF,yBACE,cAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.header {\n  margin-top: 40px;\n}\n\n.tag {\n  margin-top: 60px;\n  margin-bottom: 35px;\n  text-transform: uppercase;\n  color: rgba(140, 145, 181, 1);\n}\n\n.content {\n  padding: 60px 0;\n  background: var(--white);\n}\n\n.footer {\n  padding: 60px 0;\n}\n\n@media only screen and (max-width: 1000px) {\n  .tag {\n    margin-top: 40px;\n    margin-bottom: 20px;\n  }\n  \n  .content {\n    padding: 40px 0;\n  }\n\n  .footer {\n    padding: 40px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AJkvrv3DUvtl3gJMVEGrR",
	"header": "_1HiT08lBcAcpJvzj0lluBG",
	"tag": "SGyFGb-0lmEb5Cp4Cb66Q",
	"content": "_3JQY6RbT-5PAI81kIP6iCb",
	"footer": "_1jmzuQz0kAcK7Bcclo0N0O"
};
export default ___CSS_LOADER_EXPORT___;
