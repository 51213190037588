import { FC, SVGProps } from 'react';

export const LinkIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.8363 5.5807L14.8363 5.0807L15.3363 5.0807L15.3363 5.5807L14.8363 5.5807ZM6.35103 14.7731C6.15577 14.9683 5.83919 14.9684 5.64393 14.7731C5.44866 14.5778 5.44867 14.2612 5.64393 14.066L6.35103 14.7731ZM7.76525 5.0807H14.8363L14.8363 6.0807H7.76525L7.76525 5.0807ZM15.3363 5.5807L15.3363 12.6518L14.3363 12.6518L14.3363 5.5807L15.3363 5.5807ZM15.1899 5.93425L6.35103 14.7731L5.64393 14.066L14.4828 5.22715L15.1899 5.93425Z"
        fill="#414FEB"
      />
    </svg>
  );
};
