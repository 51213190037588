// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._17krQLBLCkrYHgORoeO1Ln{width:100%;background-color:var(--white)}._1AtwbBMNs3SA2coU5lQcEi{width:100%;height:90px;padding-top:10px;padding-bottom:10px;display:flex;align-items:center;justify-content:space-between}._1Hk7Pgp2VOPzP4iWEYzl2H{cursor:pointer;transition:.2s;display:flex;align-items:center;gap:10px}._1Hk7Pgp2VOPzP4iWEYzl2H:hover{opacity:.7;transition:.2s}._1Hk7Pgp2VOPzP4iWEYzl2H *{color:var(--blue)}@media only screen and (max-width: 700px){._1AtwbBMNs3SA2coU5lQcEi{height:120px;justify-content:center;gap:20px;flex-wrap:wrap}}", "",{"version":3,"sources":["webpack://./src/components/common/Header/Header.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,6BAAA,CAGF,yBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,mBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,yBACE,cAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,+BACE,UAAA,CACA,cAAA,CAGF,2BACE,iBAAA,CAIJ,0CACE,yBACE,YAAA,CACA,sBAAA,CACA,QAAA,CACA,cAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  background-color: var(--white);\n}\n\n.header {\n  width: 100%;\n  height: 90px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.link {\n  cursor: pointer;\n  transition: .2s;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  \n  &:hover {\n    opacity: .7;\n    transition: .2s;\n  }\n\n  & * {\n    color: var(--blue);\n  }\n}\n\n@media only screen and (max-width: 700px) {\n  .header {\n    height: 120px;\n    justify-content: center;\n    gap: 20px;\n    flex-wrap: wrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_17krQLBLCkrYHgORoeO1Ln",
	"header": "_1AtwbBMNs3SA2coU5lQcEi",
	"link": "_1Hk7Pgp2VOPzP4iWEYzl2H"
};
export default ___CSS_LOADER_EXPORT___;
