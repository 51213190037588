// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._27o64gvOY8qQejFCB9EWE0{margin-top:60px}@media only screen and (max-width: 1000px){._27o64gvOY8qQejFCB9EWE0{margin-top:40px}}", "",{"version":3,"sources":["webpack://./src/components/common/MainFiltrationTable/MainFiltrationTable.module.scss"],"names":[],"mappings":"AAAA,yBACE,eAAA,CAGF,2CACE,yBACE,eAAA,CAAA","sourcesContent":[".search {\n  margin-top: 60px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .search {\n    margin-top: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "_27o64gvOY8qQejFCB9EWE0"
};
export default ___CSS_LOADER_EXPORT___;
