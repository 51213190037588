// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2GGcDjACmSPwcdjC3pFuC3{width:100%}._3gjUekhxyZ69H5nU2mNtb4{margin-bottom:40px}._2LM7dgARqBoC2XoiLFn9qR{margin-top:40px}._2VWXwuVW2631A_rQVZi9AT{width:680px}._2VWXwuVW2631A_rQVZi9AT>*{width:100%}._3wPL51XvGMf8Mv2hKGk0vn{max-width:100%;width:max-content}.DxBRSPOV9A_SEkWlo9ZOr{margin-top:60px;width:100%;max-width:628px}@media only screen and (max-width: 1000px){._3gjUekhxyZ69H5nU2mNtb4{margin-bottom:24px}._2LM7dgARqBoC2XoiLFn9qR{margin-top:24px}}@media only screen and (max-width: 700px){._2VWXwuVW2631A_rQVZi9AT{width:100%}._2VWXwuVW2631A_rQVZi9AT>*{width:100%}}", "",{"version":3,"sources":["webpack://./src/components/common/CommonCompanyInfo/CommonCompanyInfo.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CAGF,yBACE,kBAAA,CAGF,yBACE,eAAA,CAGF,yBACE,WAAA,CAEA,2BACE,UAAA,CAIJ,yBACE,cAAA,CACA,iBAAA,CAGF,uBACE,eAAA,CACA,UAAA,CACA,eAAA,CAGF,2CACE,yBACE,kBAAA,CAGF,yBACE,eAAA,CAAA,CAIJ,0CACE,yBACE,UAAA,CAEA,2BACE,UAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.heading {\n  margin-bottom: 40px;\n}\n\n.nextTag {\n  margin-top: 40px;\n}\n\n.inner {\n  width: 680px;\n\n  & > * {\n    width: 100%;\n  }\n}\n\n.container {\n  max-width: 100%;\n  width: max-content;\n}\n\n.hint {\n  margin-top: 60px;\n  width: 100%;\n  max-width: 628px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .heading {\n    margin-bottom: 24px;\n  }\n  \n  .nextTag {\n    margin-top: 24px;\n  }\n}\n\n@media only screen and (max-width: 700px) {\n  .inner {\n    width: 100%;\n  \n    & > * {\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_2GGcDjACmSPwcdjC3pFuC3",
	"heading": "_3gjUekhxyZ69H5nU2mNtb4",
	"nextTag": "_2LM7dgARqBoC2XoiLFn9qR",
	"inner": "_2VWXwuVW2631A_rQVZi9AT",
	"container": "_3wPL51XvGMf8Mv2hKGk0vn",
	"hint": "DxBRSPOV9A_SEkWlo9ZOr"
};
export default ___CSS_LOADER_EXPORT___;
