// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q_1D_7zUOIwzMAVVoAQps{width:100%;height:100vh;display:flex;align-items:center;justify-content:center}._32cARWrCWsutvR0E-bnhUV{max-width:500px}._32cARWrCWsutvR0E-bnhUV *{margin:auto;text-align:center}._2TceaLBgnrtyKu-1iv3BgE{margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/components/common/ErrorBoundary/ErrorBoundary.module.scss"],"names":[],"mappings":"AAAA,uBACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,yBACE,eAAA,CAEA,2BACE,WAAA,CACA,iBAAA,CAIJ,yBACE,kBAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.inner {\n  max-width: 500px;\n\n  & * {\n    margin: auto;\n    text-align: center;\n  }\n}\n\n.spacer {\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "q_1D_7zUOIwzMAVVoAQps",
	"inner": "_32cARWrCWsutvR0E-bnhUV",
	"spacer": "_2TceaLBgnrtyKu-1iv3BgE"
};
export default ___CSS_LOADER_EXPORT___;
