// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._120Ow4dI3uIrMwh6mtSQg8{position:relative;cursor:pointer;border-bottom:2px dashed var(--blue)}._3b5XhW6t-uhE2dDTugw47q{position:absolute;bottom:100%;left:0px;transform:translateX(calc(-50% + 30px));width:300px;max-width:100vw;max-height:300px;overflow-x:hidden;overflow-y:auto;padding:10px 15px;border-radius:8px;background-color:var(--hint-gray);z-index:10;word-break:break-word}._3b5XhW6t-uhE2dDTugw47q::-webkit-scrollbar{width:4px}._3b5XhW6t-uhE2dDTugw47q::-webkit-scrollbar-thumb{background-color:var(--gray);border-radius:2px;border:3px solid transparent}", "",{"version":3,"sources":["webpack://./src/components/common/LibraryWordEntry/LibraryWordEntry.module.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CACA,cAAA,CACA,oCAAA,CAGF,yBACE,iBAAA,CACA,WAAA,CACA,QAAA,CACA,uCAAA,CACA,WAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,iCAAA,CACA,UAAA,CACA,qBAAA,CAEA,4CACE,SAAA,CAGF,kDACE,4BAAA,CACA,iBAAA,CACA,4BAAA","sourcesContent":[".wrapper {\n  position: relative;\n  cursor: pointer;\n  border-bottom: 2px dashed var(--blue);\n}\n\n.hint {\n  position: absolute;\n  bottom: 100%;\n  left: 0px;\n  transform: translateX(calc(-50% + 30px));\n  width: 300px;\n  max-width: 100vw;\n  max-height: 300px;\n  overflow-x: hidden;\n  overflow-y: auto;\n  padding: 10px 15px;\n  border-radius: 8px;\n  background-color: var(--hint-gray);\n  z-index: 10;\n  word-break: break-word;\n\n  &::-webkit-scrollbar {\n    width: 4px;\n  }\n  \n  &::-webkit-scrollbar-thumb {\n    background-color: var(--gray);    /* color of the scroll thumb */\n    border-radius: 2px;       /* roundness of the scroll thumb */\n    border: 3px solid transparent;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_120Ow4dI3uIrMwh6mtSQg8",
	"hint": "_3b5XhW6t-uhE2dDTugw47q"
};
export default ___CSS_LOADER_EXPORT___;
