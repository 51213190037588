// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3t5vzw504aDu6K3h9R5_S8{width:100%}._1XLlHJqJ4kG19MKPqxonw4{padding-top:40px}._3rGINLq5m-LZ4oaWUUz8N_{margin-top:60px;max-width:800px}.Q_zy2xNg12XuywWcIumGb{margin-top:70px;margin-bottom:80px}.rQYctKFsPEdLuDbNXSSQH{margin-bottom:30px}._2QC3z6KfG1kdVQodIl7OeJ{margin-bottom:60px}.FIK6RPV4mX69ei0uR-UVN{margin-bottom:80px}@media only screen and (max-width: 1000px){._1XLlHJqJ4kG19MKPqxonw4{padding-top:24px}._2QC3z6KfG1kdVQodIl7OeJ{margin-bottom:30px}._3rGINLq5m-LZ4oaWUUz8N_{margin-top:30px;max-width:800px}.Q_zy2xNg12XuywWcIumGb{margin-top:35px;margin-bottom:40px}.FIK6RPV4mX69ei0uR-UVN{margin-bottom:40px}}", "",{"version":3,"sources":["webpack://./src/pages/entities/education/SociotekaEducation/SociotekaEducation.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CAGF,yBACE,gBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAGF,uBACE,eAAA,CACA,kBAAA,CAGF,uBACE,kBAAA,CAGF,yBACE,kBAAA,CAGF,uBACE,kBAAA,CAGF,2CACE,yBACE,gBAAA,CAGF,yBACE,kBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAGF,uBACE,eAAA,CACA,kBAAA,CAGF,uBACE,kBAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.content {\n  padding-top: 40px;\n}\n\n.heading {\n  margin-top: 60px;\n  max-width: 800px;\n}\n\n.navigation {\n  margin-top: 70px;\n  margin-bottom: 80px;\n}\n\n.search {\n  margin-bottom: 30px;\n}\n\n.filtration {\n  margin-bottom: 60px;\n}\n\n.table {\n  margin-bottom: 80px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .content {\n    padding-top: 24px;\n  }\n\n  .filtration {\n    margin-bottom: 30px;\n  }\n  \n  .heading {\n    margin-top: 30px;\n    max-width: 800px;\n  }\n\n  .navigation {\n    margin-top: 35px;\n    margin-bottom: 40px;\n  }\n  \n  .table {\n    margin-bottom: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_3t5vzw504aDu6K3h9R5_S8",
	"content": "_1XLlHJqJ4kG19MKPqxonw4",
	"heading": "_3rGINLq5m-LZ4oaWUUz8N_",
	"navigation": "Q_zy2xNg12XuywWcIumGb",
	"search": "rQYctKFsPEdLuDbNXSSQH",
	"filtration": "_2QC3z6KfG1kdVQodIl7OeJ",
	"table": "FIK6RPV4mX69ei0uR-UVN"
};
export default ___CSS_LOADER_EXPORT___;
