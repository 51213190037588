// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1bfpdmoel8ecbpriSjPlw_{padding:8px 16px;border-radius:26px}._1ZNJ7dof1gVfwfntzX9BEX{background:rgba(40,188,161,.15)}._1ZNJ7dof1gVfwfntzX9BEX *{color:var(--success)}._1boQJxvoVzTm2JGWOTIKaC{background:rgba(65,79,235,.15)}._1boQJxvoVzTm2JGWOTIKaC *{color:var(--blue)}.UCRzzXJsPO7E711xWRpya{background:rgba(241,63,68,.15)}.UCRzzXJsPO7E711xWRpya *{color:var(--error)}", "",{"version":3,"sources":["webpack://./src/components/kit/Status/Status.module.scss"],"names":[],"mappings":"AAAA,yBACE,gBAAA,CACA,kBAAA,CAEA,yBACE,+BAAA,CAEA,2BACE,oBAAA,CAIJ,yBACE,8BAAA,CAEA,2BACE,iBAAA,CAIJ,uBACE,8BAAA,CAEA,yBACE,kBAAA","sourcesContent":[".styled {\n  padding: 8px 16px;\n  border-radius: 26px;\n\n  &_accepted {\n    background: rgba(40, 188, 161, 0.15);\n\n    & * {\n      color: var(--success);\n    }\n  }\n\n  &_confirmation {\n    background: rgba(65, 79, 235, 0.15);\n\n    & * {\n      color: var(--blue);\n    }\n  }\n\n  &_rejected {\n    background: rgba(241, 63, 68, 0.15);\n\n    & * {\n      color: var(--error);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styled": "_1bfpdmoel8ecbpriSjPlw_",
	"styled_accepted": "_1ZNJ7dof1gVfwfntzX9BEX",
	"styled_confirmation": "_1boQJxvoVzTm2JGWOTIKaC",
	"styled_rejected": "UCRzzXJsPO7E711xWRpya"
};
export default ___CSS_LOADER_EXPORT___;
