// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tt5GDFFKuBY4_tg0kRD0e{width:100%}._1kg2VICCllLw3EL-8OZDj8{padding-top:40px}._2gsDqc4AZjTwOO7pCcw899{margin-top:60px;max-width:800px}._326k8oeB2OBvRIq3lPazng{margin-top:70px;margin-bottom:80px}._3aBxypswzyJdp_zoHlMoET{margin-bottom:30px}._2g2WUomZHUxESmtszthjKJ{margin-bottom:60px}._sbbRM_2DxVOCGZP7oP_B{margin-bottom:80px}@media only screen and (max-width: 1000px){._1kg2VICCllLw3EL-8OZDj8{padding-top:24px}._2g2WUomZHUxESmtszthjKJ{margin-bottom:30px}._2gsDqc4AZjTwOO7pCcw899{margin-top:30px;max-width:800px}._326k8oeB2OBvRIq3lPazng{margin-top:35px;margin-bottom:40px}._sbbRM_2DxVOCGZP7oP_B{margin-bottom:40px}}", "",{"version":3,"sources":["webpack://./src/pages/entities/project/SociotekaProjects/SociotekaProjects.module.scss"],"names":[],"mappings":"AAAA,uBACE,UAAA,CAGF,yBACE,gBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CAGF,yBACE,kBAAA,CAGF,yBACE,kBAAA,CAGF,uBACE,kBAAA,CAGF,2CACE,yBACE,gBAAA,CAGF,yBACE,kBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAGF,yBACE,eAAA,CACA,kBAAA,CAGF,uBACE,kBAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.content {\n  padding-top: 40px;\n}\n\n.heading {\n  margin-top: 60px;\n  max-width: 800px;\n}\n\n.navigation {\n  margin-top: 70px;\n  margin-bottom: 80px;\n}\n\n.search {\n  margin-bottom: 30px;\n}\n\n.filtration {\n  margin-bottom: 60px;\n}\n\n.table {\n  margin-bottom: 80px;\n}\n\n@media only screen and (max-width: 1000px) {\n  .content {\n    padding-top: 24px;\n  }\n\n  .filtration {\n    margin-bottom: 30px;\n  }\n  \n  .heading {\n    margin-top: 30px;\n    max-width: 800px;\n  }\n\n  .navigation {\n    margin-top: 35px;\n    margin-bottom: 40px;\n  }\n  \n  .table {\n    margin-bottom: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Tt5GDFFKuBY4_tg0kRD0e",
	"content": "_1kg2VICCllLw3EL-8OZDj8",
	"heading": "_2gsDqc4AZjTwOO7pCcw899",
	"navigation": "_326k8oeB2OBvRIq3lPazng",
	"search": "_3aBxypswzyJdp_zoHlMoET",
	"filtration": "_2g2WUomZHUxESmtszthjKJ",
	"table": "_sbbRM_2DxVOCGZP7oP_B"
};
export default ___CSS_LOADER_EXPORT___;
